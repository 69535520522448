"use strict";

# (($, Drupal)->
#   Drupal.behaviors.AnimationCanvas = {
#     attach: (context, settings)->
#       CanvasCircles = $('.boxcircle_svg__value')
#       CirclesProcent = [
#         75
#         50
#         87.5
#         82
#       ]
#       # requestAnimationFrame Shim

#       AnimateCircle = (canvas, radius, endPercent) ->
#         context = canvas.getContext('2d')
#         x = canvas.width / 2
#         y = canvas.height / 2
#         curPerc = 0
#         counterClockwise = false
#         circ = Math.PI * 2
#         quart = Math.PI / 2

#         animate = (current) ->
#           context.clearRect 0, 0, canvas.width, canvas.height
#           context.beginPath()
#           context.arc x, y, radius, -quart, circ * current - quart, false
#           context.stroke()
#           curPerc++
#           if curPerc < endPercent
#             requestAnimationFrame ->
#               animate curPerc / 100
#               return
#           return

#         context.lineWidth = 2
#         context.strokeStyle = '#383737'
#         animate()
#         return

#       do ->
#         requestAnimationFrame = window.requestAnimationFrame or window.mozRequestAnimationFrame or window.webkitRequestAnimationFrame or window.msRequestAnimationFrame
#         window.requestAnimationFrame = requestAnimationFrame
#         return
#       canvass = []
#       CanvasCircles.each (ind, el) ->
#         canvas = $('<canvas />').attr(
#           id: 'canvas' + ind
#           width: 213
#           height: 213)
#         $(el).append canvas
#         canvass.push canvas
#         return
#       canvass.forEach (item, ind, arr)->
#         r = document.getElementById('canvas' + ind)
#         setTimeout (AnimateCircle r, 106, CirclesProcent[ind]), 1500*ind + 1500
#         return
#       # ---
#       # generated by js2coffee 2.2.0
#       return
#   }
#   return
# ) jQuery, Drupal

# (($, Drupal)->
#   Drupal.behaviors.AnimationCanvas = {
#     attach: (context, settings)->
#       CanvasCircles = $('.boxcircle_svg__value')
#       CirclesProcent = [
#         75
#         50
#         87.5
#         82
#       ]
#       CanvasCircles.each (ind, el) ->
#         $(el).circliful
#           start: 0
#           pointSize: 95
#           halfCircle: false
#           foregroundBorderWidth: 2
#           foregroundColor: '#383737'
#           backgroundColor: 'none'
#           percent: CirclesProcent[ind]
#         return
#       return
#   }
#   return
# ) jQuery, Drupal
#
(($, Drupal)->
  Drupal.behaviors.AnimationCanvas = {
    attach: (context, settings)->
      CanvasCircles = $('.boxcircle_svg__value')
      CirclesProcent = [
        100
        100
        100
        100
      ]

      fill_duration = 1.2
      fill_position = '+=.25'
      circle = '<svg version="1.1" viewBox="0 0 212 212" class="filler-loader"><circle class="fill-path" cx="106" cy="106" r="95"/></svg>';
      sctoll = $('#section-higbottom')
      ctrl = new ScrollMagic.Controller {
        globalSceneOptions: {
          triggerHook: 0
        }
      }
      CanvasCircles.each (ind, el) ->
        $(el).append circle
        loader = new TimelineMax({ repeat: 0 })
        fill_path = $(el).find('.fill-path')
        loader.fromTo( fill_path, fill_duration, { drawSVG: '0' }, { drawSVG: CirclesProcent[ind] + '%' }, fill_position, "+=1" );
        new ScrollMagic.Scene({
          duration: '0%'
          offset: -350
          reverse: false
        })
        .setTween(loader)
        .triggerElement(sctoll[0])
        .addTo(ctrl);
        return
      #TweenLite.to(window, 2, {scrollTo:"#someID"});
      return
  }
  return
) jQuery, Drupal